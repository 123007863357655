<app-loading [isLoading]="isLoading"></app-loading>
<p *ngIf="isTile">
  <a class="previous round" (click)="GoToTile()">&#8249;</a> Back to Tile
</p>
<main>
  <section class="tiles-container" *ngIf="!isTile">
    <div class="tile">
      <h2>Health Bytes</h2>
      <p class="img"></p>
      <br>
      <p>Looking after you....Keeping you informed....Keeping you up to date</p>
      <button class="btn-bl button_pdf" (click)="showHealthBytePDFs()">
        Display Contents
      </button>
    </div>

    <!-- <div class="tile">
            <h2>Tile 2</h2>
            <p>
                GeeksforGeeks is a leading
                platform that provides computer
                science resources and coding
                challenges for programmers and
                technology enthusiasts
            </p>
        </div> -->
  </section>
  <section class="tiles-container" *ngIf="isTile">
    <h2 class="tileName">{{ titleName }} PDF</h2>
    <div class="container">
        <div class="scrollbar" id="scrollbar1">
            <div class="" *ngFor="let tab of tabs; let i = index">
                <div class="">
                  <p class=""><button
                    class="btn-bl button_pdf button_pdf_open"
                    (click)="openPdfInNewWindow(tab)"
                  >
                    Open PDF
                  </button>{{ tab.fileName }}</p>
                </div>
            </div>
        </div>
    </div>
  </section>
</main>
