import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as Enums from '../../shared/enums';


// fileData: File = null;
@Injectable({ providedIn: 'root' })
export class ThirdPartyService {
	httpOptions: any = {};
	constructor(public http: HttpClient){}

    async HealthBitePDFs(): Promise<any>
    {
        return await this.http.get(Enums.THIRD_PARTY_URL + Enums.API_PATHS.GET_HEALTH_BITE_PDF).toPromise().then(
            (res: any) => {
                return res;
            },
            (err) => {
                return err;
            }
        );
    }

}