let getDomain = location.origin;
// let HLCURL = "https://mmi.myhealth360.co.za";
let HLCURL = "https://eap-service.onrender.com";
let client_id="1234567890abcdef1234567890abcdef";
let client_secret="abcdef1234567890abcdef1234567890abcdef123456";
let ned_bank_id;
let perigon_survey_url;
let third_party_url;
if(getDomain.indexOf('dev') > -1 || getDomain.indexOf('localhost') > -1){
	getDomain = 'https://healthhub-dev.momentumhealth.co.za';
	ned_bank_id="1d64c41c-4c34-4285-a819-69c79edc133d";
	perigon_survey_url = "https://perigon-dev.momentumhealthsolutions.co.za/perigon/#/survey/53C5F114-FC12-4D20-B748-555F25085CFE/";
	third_party_url = "https://dev-thirdpartycall.momentumhealthsolutions.co.za/api";
} else if(getDomain.indexOf('tst') > -1){
	getDomain = 'https://healthhub-tst.momentumhealth.co.za';
	ned_bank_id="1d64c41c-4c34-4285-a819-69c79edc133d";
	perigon_survey_url = "https://perigon-dev.momentumhealthsolutions.co.za/perigon/#/survey/53C5F114-FC12-4D20-B748-555F25085CFE/";
	third_party_url = "https://dev-thirdpartycall.momentumhealthsolutions.co.za/api";
} else if(getDomain.indexOf('pre') > -1){
	getDomain = 'https://healthhub-pre.momentumhealth.co.za';
	ned_bank_id = "1d64c41c-4c34-4285-a819-69c79edc133d";
	perigon_survey_url = "https://perigon-dev.momentumhealthsolutions.co.za/perigon/#/survey/53C5F114-FC12-4D20-B748-555F25085CFE/";
	third_party_url = "https://prod-thirdpartyapi.momentumhealthsolutions.co.za/api";
} else {
	getDomain = 'https://healthhub.momentumhealth.co.za';
	ned_bank_id = "1d64c41c-4c34-4285-a819-69c79edc133d";
	perigon_survey_url = "https://perigon.momentumhealthsolutions.co.za/perigon/#/survey/53C5F114-FC12-4D20-B748-555F25085CFE/";
	third_party_url = "https://prod-thirdpartyapi.momentumhealthsolutions.co.za/api";
}
export const environment = {
  production: false,
  apiUrl: getDomain+'/hub/api',
  hlcURL: HLCURL,
  clientId : client_id,
  clientSecret : client_secret,
  ned_bank_id:ned_bank_id,
  perigon_survey_url:perigon_survey_url,
  third_party_url:third_party_url
};

// maybe could be this one: https://member-dev.mhwellness.co.za/eap
