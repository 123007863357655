import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ThirdPartyService } from "../shared/services/third-party.service";

@Component({
  selector: "wellbeing-hub",
  templateUrl: "./wellbeing-hub.component.html",
  styleUrls: ["./wellbeing-hub.component.css"],
})
export class WellbeingHubComponent implements OnInit {
  tabs: any;
  isTile: boolean = false;
  isLoading = false;
  titleName: string;

  constructor(
    private router: Router,
    private thirdPartyService: ThirdPartyService
  ) {}

  ngOnInit(): void {
    $("body").addClass("mini-menu"); // Add mini dashboard menu
    $(".component-content").css("box-shadow", "unset"); //Remove box Shadow from global component
    $(".component-content").css("background-color", "transparent");
    this.isTile = false;
  }

  async LoadAllHealthBitePDFs() {
    this.isLoading = true;
    let pdfs = await this.thirdPartyService
      .HealthBitePDFs()
      .then((res) => {
        if (res.value.length > 0 && typeof res.value == "object") {
          this.tabs = res.value;
          this.titleName = "Health Bytes";
          this.isTile = !this.isTile;
        }
        if(typeof res.value == "string")
        alert("No PDFs found");
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        console.log(err);
      });
  }

  openPdfInNewWindow(data) {
    // Decode the Base64-encoded PDF content
    const pdfBytes = atob(data.fileContent)
      .split("")
      .map((char) => char.charCodeAt(0));

    // Create a new window and display the PDF
    const blob = new Blob([new Uint8Array(pdfBytes)], {
      type: "application/pdf",
    });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  }

  showHealthBytePDFs() {
    this.LoadAllHealthBitePDFs();
  }

  GoToTile() {
    this.isTile = !this.isTile;
  }
}
